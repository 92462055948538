import React , {useEffect, useState} from "react"
import { Container, Row, Col, Breadcrumb, Form, Button,Spinner } from 'react-bootstrap';
import { Link } from "gatsby"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bookings } from "../../utils/APIs/General_API"
import { useFormik } from "formik";
import * as Yup from "yup";
import 'react-toastify/dist/ReactToastify.css';
import "yup-phone";
import Layout from "../../components/layout/layout";


import "./book-appointment.scss";


const initialValues = {
    name: "",
    email: "",    
    phone:'',
    position:'',
    postcode:'',
    staff:'',
    message:'',
}

const validationSchema = Yup.object({
    name: Yup.string().max(100).required('Name is required field!'),
    email: Yup.string().email('Please enter valid Email').required('Email is required field!'),
    phone: Yup.string().required('Contact Number is required field!').phone('Please enter valid phone number'),
    message: Yup.string().max(500).required('Message is required field!'),
    position: Yup.string().max(100).required('Position is required field!'),
    postcode: Yup.string().max(100).required('PostCode is required field!'),
});

const BookAppointmentPage = () => {
    
    const [formMessages, setFormMessage] = useState('');
	const [initialValue, setInitialValue] = useState(initialValues);
    const [isValidate, setIsvalidate] = useState(false);
    const [loginButtonLoading, setLoginButtonLoading] = useState(false);


	const { handleSubmit, handleChange, values, setFieldValue, errors, resetForm } = useFormik({
        enableReinitialize: true,
        initialValues: initialValue,
        validationSchema,
        validateOnChange: isValidate,
        onSubmit(values, { resetForm }) {
            setFormMessage('');
            setIsvalidate(true)
            setLoginButtonLoading(true)

            var forminfo = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                position: values.position,
                postcode: values.postcode,
                staff: values.staff,
                message: values.message
            }

            return sendMessage(forminfo, resetForm, values);
        }
    });

    const sendMessage = (list, resetForm, values) => {
        bookings(list).then(res => {
            const resData = res.data;
            if (resData && resData.code == 200) {
                setLoginButtonLoading(false)
                resetForm({})
                toast.dismiss();
                toast.success("Thanks you for booking, we will contact you shortly!", {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(err => {
            setLoginButtonLoading(false)
            const error = err.response;
            console.log(error);
            if (error.status == 422) {
                if (error?.data?.message?.errors) {
                    {
                        if (error?.data?.message?.errors?.email) {
                            setFormMessage(error?.data?.message?.errors?.email);
                        } else if (error?.data?.message?.errors?.phone) {
                            setFormMessage(error?.data?.message?.errors?.phone);
                        } else if (error?.data?.message?.errors?.name) {
                            setFormMessage(error?.data?.message?.errors?.name);
                        } else if (error?.data?.message?.errors?.message) {
                            setFormMessage(error?.data?.message?.errors?.message);
                        } else {

                        }
                    }
                }
            } else {
                setFormMessage('Something Went Wrong!');
            }
        })
    }
    
    return (
        <Layout>
            <>
                <section className="sec-common-page-heading" style={{ backgroundImage: "url('../images/laughing-doctors.jpg')" }}>
                    <Container>
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Book Appointment</h2>
                            <Breadcrumb>
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Book Appointment
                                </li>
                            </Breadcrumb>
                        </div>
                    </Container>
                </section>


                <section className="sec-nurse">
                    <Container> 
                        <div className="nurse-content">
                            <h3>Book a nurse or carer</h3>
                            
                            <p className="mb-5">You can book shifts via following methods: by E-mail, by fax or telephone Our Registered Nurses and Care Assistants can be booked on an AD-HOC or ongoing basis, Short notice, single shift covers for your immediate needs.</p>                            
                        </div>                                
                    </Container>

                    <Container>
                        <Row>
                            <Col md="6" lg="6" xl="4">
                                <div className="nurse-wrapper">
                                    <div className="img-thumb" style={{ backgroundImage: "url('./../staff/img-1.jpg')", backgroundPosition: "0px 15%" }}></div>
                                    <div className="content">
                                        <h4>Short Term/ Long Term</h4>
                                        <p>Cover is often required for maternity leave, annual leave or long-term illness.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md="6" lg="6" xl="4">
                                <div className="nurse-wrapper">
                                    <div className="img-thumb" style={{ backgroundImage: "url('./../staff/img-2.jpg')", backgroundPosition: "0px 15%" }}></div>
                                    <div className="content">
                                        <h4>Permanent</h4>
                                        <p>Upon your request, we will advertise, interview and introduce suitable, qualified staff for your final interview (terms and conditions apply)</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md="6" lg="6" xl="4">
                                <div className="nurse-wrapper">
                                    <div className="img-thumb" style={{ backgroundImage: "url('./../staff/img-3.jpg')", backgroundPosition: "0px 15%" }}></div>
                                    <div className="content">
                                        <h4>24-hour Staffing Coordinators</h4>
                                        <p>Our Staffing Coordinators are available twenty-four hours a day, seven days a week, and 365 days a year. Our Administration team and our D.O.N. (Director of Nursing) are available between Monday to Friday.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="sec-nurse-quality">
                    <Container className="mb-5"> 
                        <div className="nurse-content">
                            <h3 className="mb-2">Quality</h3>
                            <p>We are committed to delivering the finest quality services, and can be relied upon for our knowledge, accuracy, consistency, efficiency, and responsibility.</p>
                            <p>In order to deliver a quality services FHS depends on the skills and dedication of its workers. We therefore invest in our staff in a way that values them as our best asset. We are proactive in responding to complaints, comments and feedback we receive from our clients, staff and anyone concerned about the service we provide.</p>
                            <p className="mb-4">Satisfaction surveys and spot checks are performed by compliance managers to monitor the quality of our work force.</p>
                        </div>
                    </Container>

                    <Container>
                        <Row>
                            <Col md="6" lg="6" xl="3">
                                <div className="nurse-wrapper">
                                    <div className="icon">
                                        <i className="bi bi-person"></i>
                                    </div>  
                                    <div className="content">
                                        <h4>Customer Focus</h4>
                                        <p>We never fail to remember that the customer is the reason we are in business.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md="6" lg="6" xl="3">
                                <div className="nurse-wrapper">
                                    <div className="icon">
                                        <i className="bi bi-building"></i>
                                    </div>
                                    <div className="content">
                                        <h4>Integrity</h4>
                                        <p>We conduct our business within an ethical framework of honesty and fairness.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md="6" lg="6" xl="3">
                                <div className="nurse-wrapper">
                                    <div className="icon">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <div className="content">
                                        <h4>Unity</h4>
                                        <p>We believe in teamwork and communication to achieve our common goal. We work to resolve differences in a positive, appropriate and forthright manner. We foster an environment where employees are challenged, fulfilled, rewarded and recognized.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md="6" lg="6" xl="3">
                                <div className="nurse-wrapper">
                                    <div className="icon">
                                        <i className="bi bi-graph-up-arrow"></i>
                                    </div>
                                    <div className="content">
                                        <h4>Growth</h4>
                                        <p>We are committed to personal and professional growth, and to constantly building and improving our business.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="sec-contact bg-f7">

                    <Container className="text-center mb-5">
                        <div className="contact-content">
                            <h3 className="title">Book Appointment</h3>
                            <h3>You can also book by E-mail or directly calling us</h3>
                        </div>
                    </Container>

                    <Container className="pt-5">
                        <Row className="">
                            <Col lg="6">                                
                                <div className="address-timming">
                                    <div className="address">
                                        <div className="icon">
                                            <i className="bi bi-geo-alt"></i>
                                        </div>
                                        <div className="heading">Get Consultation</div>
                                        <h6>Phone: <a href="tel:+447854 031076">+447854 031076</a></h6>
                                        <h6>Location: <span>Saturn Centre, Spring Road, Ettingshall, Wolverhampton, WV4 6JX</span></h6>
                                        <h6>Email: <a href="mailto:info@firstcallhealthcareservices.co.uk">info@firstcallhealthcareservices.co.uk</a></h6>
                                    </div>

                                    <div className="timming">
                                        <div className="icon">
                                            <i className="bi bi-clock"></i>
                                        </div>
                                        <div className="heading">Open Hours</div>
                                        <h6>Mon - Fri: <span>9 AM to 5 PM</span></h6>
                                        <h6>Sun: <span>Closed</span></h6>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6">
                                <div className="contact-form">
                                    <h4 className="mb-4">Enter your details to book appointment</h4>
                                    <Form onSubmit={handleSubmit} autoComplete="off" encType="multipart/form-data">
                                        <Form.Group className="mb-4" controlId="formFullName">
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                id="name"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name ? <p className="error text-danger">{errors.name}</p> : null}
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formEmail">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Email"
                                                id="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email ? <p className="error text-danger">{errors.email}</p> : null}
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formPhone">
                                            <Form.Control
                                                type="text"
                                                placeholder="Phone"
                                                id="phone"
                                                name="phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                            />
                                            {errors.phone ? <p className="error text-danger">{errors.phone}</p> : null}
                                        </Form.Group>
                                        {/* <Form.Group className="mb-4">
                                            <Form.Control type="text" placeholder="Position ( Exp: Doctor, Hospital Staff )" name="position" onChange={handleChange}/>
                                        </Form.Group> */}
                                        <Form.Group className="mb-4" controlId="formposition">
                                            <Form.Control
                                                type="text"
                                                placeholder="Position ( Exp: Doctor, Hospital Staff )"
                                                id="position"
                                                name="position"
                                                value={values.position}
                                                onChange={handleChange}
                                            />
                                            {errors.position ? <p className="error text-danger">{errors.position}</p> : null}
                                        </Form.Group>
                                        {/* <Form.Group className="mb-4">
                                            <Form.Control type="text" placeholder="Enter Post code" name="postcode" onChange={handleChange}/>
                                        </Form.Group> */}
                                        <Form.Group className="mb-4" controlId="formpostcode">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Post code"
                                                id="postcode"
                                                name="postcode"
                                                value={values.postcode}
                                                onChange={handleChange}
                                            />
                                            {errors.postcode ? <p className="error text-danger">{errors.postcode}</p> : null}
                                        </Form.Group>
                                        <Form.Group className="mb-4">                                            
                                            <div className="flex-justify-between">
                                                <Form.Label>What do you want?</Form.Label>
                                                <div>
                                                    <Form.Check
                                                        inline
                                                        label="Nurse"
                                                        name="staff"
                                                        type="radio"
                                                        id="inline-1"
                                                        value="nurse"
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Carer"
                                                        name="staff"
                                                        type="radio"
                                                        id="inline-2"
                                                        value="carer"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formMessage">
                                            <Form.Control as="textarea" rows={3} placeholder="Message"  name="message" value={values.message}
                                                onChange={handleChange}/>
                                            {errors.message ? <p className="error text-danger">{errors.message}</p> : null}
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <button type="submit" className="btn btn-primary" disabled={loginButtonLoading}>
                                                {
                                                    loginButtonLoading ? (
                                                        <Spinner animation="border" size="sm" />
                                                    ) : (
                                                        <span>Submit</span>
                                                    )
                                                }
                                            </button>
                                        </div>

                                        <Form.Group className="mt-3">
                                            {formMessages ? <p className="error text-danger alert alert-danger">{formMessages}</p> : null}
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>

                {/* <section className="sec-get-in-touch">
                    <Container>
                        <Row>
                            <Col md="6" lg="7" xl="8">
                                <h3>Need a Nurse/Carer</h3>
                            </Col>
                            <Col md="6" lg="5" xl="4" className="contact-btn">
                                <Link to="/contact" className="btn btn-primary">Contact Us</Link>
                            </Col>
                        </Row>
                    </Container>
                </section> */}

            </>
        </Layout>
    )
}

export default BookAppointmentPage
